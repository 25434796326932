import React from 'react';
//import { Chrome } from './src/components/chrome/chrome';
import { ApolloProvider } from '@apollo/client';
import { client } from './src/apollo/client';

export const wrapRootElement = ({ element }) => {
  return (<ApolloProvider client={client}>
            { element }
        </ApolloProvider>
  )
};

