import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import fetch from 'isomorphic-fetch';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        filtersReactive: {
          read() {
            return filtersStore();
          }
        },
        searchReactive: {
          read() {
            return searchStore();
          }
        },
      }
    }
  }
});


export const filtersStore = cache.makeVar({ test: 123 });
export const searchStore = cache.makeVar({ test: 123 });

const contentfulToken = 'h9-YffUYitVIInggk4h7P4LHdqjIQ60QgKRMHwcK7DU';
const contentfulSpaceId = 'bx15do6v16qc';

export const client = new ApolloClient({
    link: new HttpLink ({
      fetch,
      uri: `https://graphql.contentful.com/content/v1/spaces/${contentfulSpaceId}?access_token=${contentfulToken}`,
    }),
    cache
});




  
 